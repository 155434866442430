// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx": () => import("./../../../../packages/themes/gatsby-theme-flexiblocks/src/pages/404.jsx" /* webpackChunkName: "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-preise-index-jsx": () => import("./../../../src/pages/preise/index.jsx" /* webpackChunkName: "component---src-pages-preise-index-jsx" */),
  "component---src-pages-ueber-uns-index-jsx": () => import("./../../../src/pages/ueber-uns/index.jsx" /* webpackChunkName: "component---src-pages-ueber-uns-index-jsx" */),
  "component---src-pages-was-ist-jamstack-index-jsx": () => import("./../../../src/pages/was-ist-jamstack/index.jsx" /* webpackChunkName: "component---src-pages-was-ist-jamstack-index-jsx" */)
}

